import React from "react";


export async function getData(url: string, noJson?: boolean): Promise<any> {
    let headers = new Headers();

    // Default options are marked with *
    // headers.append("Content-Type", "application/json");
    // headers.append("Accept", "text/plain");
    // headers.append("Origin", "http://localhost:3000");

    const response = await fetch(url, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        headers: headers,
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    if (response.ok) {
        if (noJson) {
            return response;
        }
        return response.json();
        // parses JSON response into native JavaScript objects
    } else {
        const text = (await response.text()).valueOf();
        throw new Error(text);
    }
}
