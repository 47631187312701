import React, { useEffect, useState } from "react";
import "./App.css";
import Line from "./components/Line";
import { numberWithCommas } from "./global/dummyData";
import { Box, Button, LinearProgress } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Bar from "./components/Bar";
import Radial from "./components/Radial";
import { getData } from "./global/fetch-data";
import Grid from "@mui/material/Grid";

import Leaf_image from "../src/Leaf.png";
import Tree_image from "../src/Tree.png";
import Panel_image from "../src/Panel.png";
import Absolar_logo from "../src/Absolar_logo_black.png";
import USSP_logo from "../src/USSP_logo.png";
import { Weather } from "./components/Weather";
import { Headline } from "./components/Headline";
import image from "./images/dekra_image.png";

import {
  Co2Data,
  SolisDay,
  SolisYear,
  ValueDataDto,
  ValueDataNew,
} from "./global/interfaces";
import { Refresh } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { linearProgressClasses } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { InfinitySpin } from "react-loader-spinner";
import { Layout } from "./components/Babel";
// import Forecast from "react-forecast";
const styleAnimation = { "animation-delay": 0.5 + 0 / 10 + "s" };

const localUrl = "https://monitoringapi.absolar.co.uk/";
const theme = createTheme({
  palette: {
    success: {
      light: "#ff6333",
      main: "#ff3d00",
      dark: "#darkred",
      contrastText: "#000",
    },
  },
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#ff6333" : "#ff6333",
  },
}));

function App() {
  const [kwh, setKwh] = useState(0);

  const [tokenNew, setTokenNew] = useState<string | null>(null);

  const [kwhNew, setKwhNew] = useState(0);
  const [minutesNew, setMinutesNew] = useState(1);

  // const [weekState, setWeekState] = useState<Array<ValueDataNew> | undefined>(
  //   undefined
  // );
  const [animationShow, setAnimationShow] = useState<boolean>(false);
  const [animationShow2, setAnimationShow2] = useState<boolean>(false);

  const [dayStateNew, setDayStateNew] = useState<
    Array<ValueDataNew> | undefined
  >(undefined);
  const [yearState, setYearState] = useState<SolisYear | undefined>(undefined);
  const [tokens, setTokens] = useState<{
    token: string | null;
    refreshToken: string | null;
  }>({ token: null, refreshToken: null });
  const [todayCo2, setTodayCo2] = useState<number>(0);
  const [totalCo2, setTotalCo2] = useState<number>(0);

  const [totalCo2New, setTotalCo2New] = useState<number>(0);
  const [todayCo2New, setTodayCo2New] = useState<number>(0);

  const [showGraph, setShowGraph] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [minutesPeriod, setMinutesPeriod] = useState(false);

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }
  function formatDate(date: Date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getUTCMonth() + 1),
      padTo2Digits(date.getUTCDate()),
    ].join("-");
  }

  const getToday = () => {
    const currentDate = new Date();
    const tomorrow = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 3
    );
    let yearUrl =
      localUrl +
      "api/Solis/yearValues?year=" +
      currentDate.getUTCFullYear() +
      "&siteId=1298491919448845479";

    let dayUrl =
      localUrl +
      "api/Solis/dayValues?start=" +
      formatDate(currentDate) +
      "&siteId=1298491919448845479";

    setMinutesNew(1);
    getData(yearUrl)
      .then((data: SolisYear) => {
        setYearState(data);
      })
      .catch((e) => {
        console.log("couldn't get year data ");
      });
    getData(dayUrl)
      .then((data: SolisDay) => {
        console.log("TodayNew", data);
        const currentDateStr = formatDate(currentDate);
        setDayStateNew(
          data.data.map((x) => {
            return {
              time: currentDateStr + "T" + x.timeStr + "Z",
              pvGeneration: x.produceEnergy,
            };
          })
        );

        let nowData = data.data
          .map((x) => x.produceEnergy)
          .slice(-1)
          .pop();

        setKwhNew(nowData ? nowData : 0);

        let nowTime = data.data
          .map((x) => new Date(x.timeStr))
          .slice(-1)
          .pop();
      })
      .catch((e) => {
        console.log("Failed to get Today's data", e);
      });
  };

  const MINUTE_MS = 60000;

  useEffect(() => {
    const intervalId4 = setInterval(() => setShowGraph(!showGraph), 10000);
    return () => clearInterval(intervalId4);
  }, [showGraph]);
  useEffect(() => {
    const intervalId5 = setInterval(
      () => setReloadData(!reloadData),
      5 * MINUTE_MS
    );
    getToday();
    return () => clearInterval(intervalId5);
  }, [reloadData]);

  useEffect(() => {
    if (animationShow) {
      const interval = setInterval(
        () => setAnimationShow(false),
        MINUTE_MS / 4
      );

      return () => clearInterval(interval);
    }
  }, [animationShow]);

  useEffect(() => {
    if (animationShow2) {
      const interval = setInterval(
        () => setAnimationShow2(false),
        MINUTE_MS / 4
      );

      return () => clearInterval(interval);
    }
  }, [animationShow2]);

  useEffect(() => {
    const intervalId1 = setInterval(
      () => setMinutesPeriod(!minutesPeriod),
      MINUTE_MS
    );
    let minute = minutesNew + 1;
    setMinutesNew(minute);
    return () => clearInterval(intervalId1);
  }, [minutesPeriod]);

  useEffect(() => {
    // getToken();
    getToday();
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return (
    <div
      style={{
        //testing purposes (on a laptop)
        // marginBottom: "10rem",
        // width: isPortrait ? "432px" : isBigScreen ? "" : "432px",
        // height: isPortrait ? "768px" : "",
        // margin: "auto",
        marginBottom: "10rem",
        // width: isPortrait ? "1080px" : isBigScreen ? "" : "1080px",
        // height: isPortrait ? "1920" : "",
        margin: "auto",
      }}
      className="App"
    >
      {dayStateNew ? (
        <div
          style={{
            margin: "auto",
            // paddingRight: "20px",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid item xs= {!isPortrait ? 9 : 12} >
              <img
                src={image}
                style={{ width: "-webkit-fill-available", display: "flex" }}
              />
            </Grid>
            {isPortrait ? null : (
            <Grid
              item
              xs={3}
              container
              direction="column"
              spacing={2}
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                <div className="headline_container">
                  <div>
                    <span className="headline_number">
                      {animationShow2 ? (
                        <Layout
                          text={
                            yearState
                              ? numberWithCommas(yearState.co2.toFixed(2))
                              : "loading"
                          }
                        />
                      ) : (
                        <>
                          {" "}
                          {yearState
                            ? numberWithCommas(yearState.co2.toFixed(2))
                            : "loading"}
                        </>
                      )}
                      <sub
                        style={{
                          fontSize: "2vw",
                        }}
                      >
                        {" "}
                        KG
                      </sub>
                    </span>
                  </div>
                  <div className="headline_desc">
                    CO<sub>2</sub> Avoided this year{" "}
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="headline_container">
                  <div>
                    <span className="headline_number">
                      {animationShow2 ? (
                        <Layout
                          text={
                            yearState
                              ? numberWithCommas(yearState.mwh.toFixed(2))
                              : "loading"
                          }
                        />
                      ) : (
                        <>
                          {" "}
                          {yearState
                            ? numberWithCommas(yearState.mwh.toFixed(2))
                            : "loading"}
                        </>
                      )}
                      <sub
                        style={{
                          fontSize: "2vw",
                        }}
                      >
                        {" "}
                        MWh
                      </sub>
                    </span>
                  </div>
                  <div className="headline_desc">Generation this year </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="headline_container">
                  <div>
                    <span className="headline_number">
                      {animationShow2 ? (
                        <Layout
                          text={
                            yearState
                              ? numberWithCommas(
                                  yearState.moneySaving.toFixed(2)
                                )
                              : "loading"
                          }
                        />
                      ) : (
                        <>
                          {" "}
                          {yearState
                            ? numberWithCommas(yearState.moneySaving.toFixed(2))
                            : "loading"}
                        </>
                      )}
                      <sub
                        style={{
                          fontSize: "2vw",
                        }}
                      >
                        {" "}
                        GBP
                      </sub>
                    </span>
                  </div>
                  <div className="headline_desc">
                    Equivalent cost this year{" "}
                  </div>
                </div>
              </Grid>

              {/* </Grid> */}
            </Grid>)}
            <Grid
              item
              // xs={isPortrait ? 12 : 6}
              xs={12} sm={12} md={12} lg={6}
            >
              <div
                className="box"
                style={{
                  height: "100%",
                  padding: "15px 5px 0px 5px",
                }}
              >
                <Line
                  values={dayStateNew}
                  height={isPortrait || !isBigScreen ? "400px" : "410px"}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12} sm={12} md={12} lg={6}
            >
              <div className="box" style={{ height: "100%" }}>
                <div style={{ marginTop: 20 }}>
                  <div style={{ color: "white" }}>
                    <Radial
                      data={kwhNew != 0 ? kwhNew / 1000 : kwh / 1000}
                      height={isPortrait || !isBigScreen ? "500px" : "350px"}
                      title="Current production"
                    />

                    {/* <h2>Power production</h2> */}
                    <div>
                      <span
                        style={
                          isBigScreen
                            ? {
                                fontSize:
                                  isPortrait || !isBigScreen ? "3rem" : "5rem",
                              }
                            : { fontSize: "1rem" }
                        }
                      >
                        {kwhNew != 0
                          ? (kwhNew / 1000).toFixed(0)
                          : (kwh / 1000).toFixed(0)}{" "}
                        kW
                      </span>
                    </div>
                    <span
                      style={{
                        fontSize:
                          isPortrait || !isBigScreen ? "0.8rem" : "1.3rem",
                        marginTop: "45px",
                      }}
                    >
                      {" "}
                      Updated: {minutesNew}{" "}
                      {minutesNew > 1 ? "minutes" : "minute"} ago
                    </span>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <ThemeProvider theme={theme}>
                      {kwhNew != 0 ? (
                        kwhNew / 1000 < 10 ? (
                          <BorderLinearProgress
                            variant="determinate"
                            value={100}
                          />
                        ) : (
                          <LinearProgress color="success" />
                        )
                      ) : kwh / 1000 < 10 ? (
                        <BorderLinearProgress
                          variant="determinate"
                          value={100}
                        />
                      ) : (
                        <LinearProgress color="success" />
                      )}
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </Grid>
            {!isPortrait ? ( //if it's portrait or a small screen - they're already shown at the top so no need to dispaly anymore
              <></>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={4} style={{  height: "100%" }}>
                  <div className="box" style={{ padding: "10px" }}>
                    <div>
                      <span
                        style={{
                          color: "white",
                          fontSize: "3.5rem",
                        }}
                      >
                        {animationShow2 ? (
                          <Layout
                            text={
                              yearState
                                ? numberWithCommas(yearState.co2.toFixed(2))
                                : "loading"
                            }
                          />
                        ) : (
                          <>
                            {" "}
                            {yearState
                              ? numberWithCommas(yearState.co2.toFixed(2))
                              : "loading"}
                          </>
                        )}
                        <sub
                          style={{
                            fontSize: "1.5rem",
                          }}
                        >
                          {" "}
                          KG
                        </sub>
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "24px",
                        color: "white",
                        marginBottom: "15px",
                      }}
                    >
                      CO<sub>2</sub> Avoided this year{" "}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}   style={{ height: "100%" }}>
                  <div className="box" style={{ padding: "10px" }}>
                    <div>
                      <span
                        style={{
                          color: "white",
                          fontSize: "3.5rem",
                        }}
                      >
                        {animationShow2 ? (
                          <Layout
                            text={
                              yearState
                                ? numberWithCommas(yearState.mwh.toFixed(2))
                                : "loading"
                            }
                          />
                        ) : (
                          <>
                            {" "}
                            {yearState
                              ? numberWithCommas(yearState.mwh.toFixed(2))
                              : "loading"}
                          </>
                        )}
                        <sub
                          style={{
                            fontSize: "1.5rem",
                          }}
                        >
                          {" "}
                          MWh
                        </sub>
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "24px",
                        color: "white",
                        marginBottom: "15px",
                      }}
                    >
                      Generation this year{" "}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}  style={{  height: "100%" }}>
                  <div className="box" style={{ padding: "10px" }}>
                    <div>
                      <span
                        style={{
                          color: "white",
                          fontSize: "3.5rem",
                        }}
                      >
                        {animationShow2 ? (
                          <Layout
                            text={
                              yearState
                                ? numberWithCommas(
                                    yearState.moneySaving.toFixed(2)
                                  )
                                : "loading"
                            }
                          />
                        ) : (
                          <>
                            {" "}
                            {yearState
                              ? numberWithCommas(
                                  yearState.moneySaving.toFixed(2)
                                )
                              : "loading"}
                          </>
                        )}
                        <sub
                          style={{
                            fontSize: "1.5rem",
                          }}
                        >
                          {" "}
                          GBP
                        </sub>
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: "24px",
                        color: "white",
                        marginBottom: "15px",
                      }}
                    >
                      Equivalent cost this year{" "}
                    </div>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              marginBottom: 20,
              display:"flex",
              alignItems:"center", 
              justifyContent:"center"
            }}
          >
            <span style={{ paddingRight: 20 }}>Powered by </span>
            <img src={Absolar_logo} style={{ width: 120 }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            margin: "auto",
            // width: "50%",
            // padding: "10px",
            alignContent: "center",
            alignItems: "center",
            marginTop: "45vh",
          }}
        >
          <InfinitySpin width="200" color="#ff3d00" />
        </div>
      )}
    </div>
  );
}

export default App;
