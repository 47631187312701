import React, { useState } from "react";
import Chart from "react-apexcharts";

export default function Radial(props) {
    // const series = props.data.map(x => x[1]).slice(3);
    const maxValue = 110;
    const minValue = 0;

    const valueToPercent = (val) => ((val - minValue) * 100) / (maxValue - minValue);

    const series = [valueToPercent(props.data).toFixed(0),]



    var trigoStrength = 3
    var iteration = 11

    function getRandom() {
        var i = iteration;
        return (Math.sin(i / trigoStrength) * (i / trigoStrength) + i / trigoStrength + 1) * (trigoStrength * 2)
    }

    const options = {
        chart: {
            type: 'radialBar',
            height: 250,
            offsetY: -30,
            offsetX: 0,
            sparkline: {
                enabled: true
            }

        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                hollow: {
                    margin: 5,
                    size: '60%',
                    background: 'transparent',
                },

                track: {
                    show: true,
                    background: '#40475D',
                    strokeWidth: '10%',
                    opacity: 1,
                    margin: 3, // margin is in pixels
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px'
                    }
                }
            }
        },

        // plotOptions: {
        //     radialBar: {
        //         size: undefined,
        //         inverseOrder: false,
        //         // hollow: {
        //         //     margin: 5,
        //         //     size: '60%',
        //         //     background: 'transparent',
        //         // },
        //         dataLabels: {
        //             value: {
        //                 formatter: (val) => (val * maxValue) / 100 + " kW",
        //             },

        //         },
        //         track: {
        //             show: true,
        //             background: '#40475D',
        //             strokeWidth: '10%',
        //             opacity: 1,
        //             margin: 3, // margin is in pixels
        //         },
        //         total: {
        //             show: true,
        //             label: 'Total',
        //             formatter: function (w) {
        //                 // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
        //                 return w + " kW"
        //             }
        //         }


        //     },
        // },

        labels: ["kW"],

        legend: {
            innerWidth: 40,
            show: false,
            position: 'left',
            offsetX: -30,
            offsetY: 10,
            formatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%"
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        title: {
            text: 'Now',
            align: 'left',
            style: {
                fontSize: '12px'
            }
        },
    };
    return (
        <div id="chart">
            <Chart options={options} series={series} type="radialBar" height={props.height}  />
        </div>
    );
}