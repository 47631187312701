import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { fixString, numberWithCommas, xaxisCatData } from "../global/dummyData";
import { ValueDataNew } from "../global/interfaces";
import "../App.css";

function timeConvert(n: number) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  return rhours;
}
function timeConvertMin(n: number) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  if (rminutes == 0) {
    return "";
  } else if (rminutes < 10) {
    return ":0" + rminutes.toString();
  } else {
    return ":" + rminutes.toString();
  }
}
interface Props {
  values?: Array<ValueDataNew>;
  height?: string;
}
const xaxisCat = xaxisCatData;

export default function Line(props: Props) {
  function isDST(d: Date) {
    let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== d.getTimezoneOffset();    
}
  function padSeries(values: ValueDataNew[]) {
    const kwValues = values.map((x) => x.pvGeneration / 1000);
    try {
      if (values.length > 0) {
        var initialTime = new Date(values[0].time);
        console.log(initialTime)
        let datapointsToAddStart = 0;
        if (initialTime.getUTCHours() != 0) {
          if(isDST(new Date())){
            datapointsToAddStart = (initialTime.getUTCHours()+1) * 12;
          }else{
            datapointsToAddStart = initialTime.getUTCHours()* 12;

          }
        }
        if (initialTime.getUTCMinutes() != 0) {
          datapointsToAddStart +=(initialTime.getUTCMinutes()-5) / 5;
        }
        console.log(datapointsToAddStart)

        let datapointsStart = new Array(datapointsToAddStart).fill(null);
        console.log()
        return datapointsStart
          .concat(kwValues)
          .concat(new Array(288 -(kwValues.length+datapointsStart.length)).fill(null));

      } else {
        return kwValues.concat(new Array(288 - kwValues.length).fill(null));
      }
    } catch (e) {
      return kwValues.concat(new Array(288 - kwValues.length).fill(null));
    }
  }
  const series = [
    {
      name: "Hourly",
      data: props.values ? padSeries(props.values) : [],
    },
  ];
  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: "line",
      stacked: true,
      // animations: {
      //     enabled: false,
      //     easing: "linear",
      //     dynamicAnimation: {
      //         speed: 1000,
      //     },
      // },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      dropShadow: {
        enabled: true,
        opacity: 0.3,
        blur: 5,
        left: -7,
        top: 22,
      },

      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: function (value: any) {
          return numberWithCommas(value.toFixed(0).toString()) + " kW";
        },
      },
      x: {
        formatter: function (value: any) {
          if (timeConvert(parseFloat(value) * 5) < 12) {
            if (timeConvert(parseFloat(value) * 5) == 0) {
              return 12 + " AM";
            } else {
              return (
                timeConvert(parseFloat(value) * 5) +
                timeConvertMin(parseFloat(value) * 5).toString() +
                " AM"
              );
            }
          } else {
            if (timeConvert(parseFloat(value) * 5) - 12 == 0) {
              return 12 + " PM";
            } else {
              return (
                timeConvert(parseFloat(value) * 5) -
                12 +
                timeConvertMin(parseFloat(value) * 5).toString() +
                " PM"
              );
            }
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2.5,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    markers: {
      size: 0,
      hover: {
        size: 0,
      },
    },
    xaxis: {
      type: "category",
      categories: xaxisCat,

      // tickAmount: 6,
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: "11px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 500,
          cssClass: "chart-labels",
        },
        offsetX: 0,
        offsetY: 0,
        formatter: function (value: string) {
          return value === "-1" ? "" : value;
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: [
      {
        show: true,
        showForNullSeries: false,
        seriesName: "Hourly kW",
        labels: {
          formatter: (value: number) => {
            return numberWithCommas(value.toFixed(0).toString()) + " kW";
          },
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
    ],
    title: {
      text: "Today",
      align: "left",
      style: {
        fontSize: "12px",
      },
    },
    legend: {
      show: true,
      floating: true,
      horizontalAlign: "left",
      onItemClick: {
        toggleDataSeries: false,
      },
      position: "top",
      offsetY: -28,
      offsetX: 60,
    },

    // colors: ["black"],
    // fill: {
    //     type: "gradient",
    //     gradient: {
    //         shadeIntensity: 1,
    //         inverseColors: true,
    //         gradientToColors: ["#DB162F"],
    //         opacityFrom: 1,
    //         opacityTo: 1,
    //         type: "vertical",
    //         stops: [0, 30]
    //     }
    // }
  });
  useEffect(()=>{
    setOptions({
      chart: {
        height: 350,
        type: "line",
        stacked: true,
        // animations: {
        //     enabled: false,
        //     easing: "linear",
        //     dynamicAnimation: {
        //         speed: 1000,
        //     },
        // },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        dropShadow: {
          enabled: true,
          opacity: 0.3,
          blur: 5,
          left: -7,
          top: 22,
        },
  
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        theme: "dark",
        y: {
          formatter: function (value: any) {
            return numberWithCommas(value.toFixed(0).toString()) + " kW";
          },
        },
        x: {
          formatter: function (value: any) {
            if (timeConvert(parseFloat(value) * 5) < 12) {
              if (timeConvert(parseFloat(value) * 5) == 0) {
                return 12 + " AM";
              } else {
                return (
                  timeConvert(parseFloat(value) * 5) +
                  timeConvertMin(parseFloat(value) * 5).toString() +
                  " AM"
                );
              }
            } else {
              if (timeConvert(parseFloat(value) * 5) - 12 == 0) {
                return 12 + " PM";
              } else {
                return (
                  timeConvert(parseFloat(value) * 5) -
                  12 +
                  timeConvertMin(parseFloat(value) * 5).toString() +
                  " PM"
                );
              }
            }
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 2.5,
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 0,
        },
      },
      xaxis: {
        type: "category",
        categories: xaxisCat,
  
        // tickAmount: 6,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [],
            fontSize: "11px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 500,
            cssClass: "chart-labels",
          },
          offsetX: 0,
          offsetY: 0,
          formatter: function (value: string) {
            return value === "-1" ? "" : value;
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          show: true,
          showForNullSeries: false,
          seriesName: "Hourly kW",
          labels: {
            formatter: (value: number) => {
              return numberWithCommas(value.toFixed(0).toString()) + " kW";
            },
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
      ],
      title: {
        text: "Today",
        align: "left",
        style: {
          fontSize: "12px",
        },
      },
      legend: {
        show: true,
        floating: true,
        horizontalAlign: "left",
        onItemClick: {
          toggleDataSeries: false,
        },
        position: "top",
        offsetY: -28,
        offsetX: 60,
      },
  
      // colors: ["black"],
      // fill: {
      //     type: "gradient",
      //     gradient: {
      //         shadeIntensity: 1,
      //         inverseColors: true,
      //         gradientToColors: ["#DB162F"],
      //         opacityFrom: 1,
      //         opacityTo: 1,
      //         type: "vertical",
      //         stops: [0, 30]
      //     }
      // }
    })


  },[props.values?.length])
  return (
    <div id="chart">
      <Chart options={options} series={series} type="line" height={props.height} />
    </div>
  );
}
