// const { Component, Children, PropTypes } = React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

function SplitText(props) {
    return (
        <>
            {props.copy != undefined && props.copy != null ? (
                <span aria-label={props.copy} role={props.role}>
                    {props.copy.split("").map(function (char, index) {
                        let style = {
                            "animation-delay": 0.5 + index / 10 + "s",
                        };
                        return (
                            <span aria-hidden="true" key={index} style={style}>
                                {char}
                            </span>
                        );
                    })}
                </span>
            ) : (
                <></>
            )}
        </>
    );
}

export function Layout(props) {
    return (
        <>
            {props.text != undefined && props.text != null ? (
                <span>
                    <SplitText copy={props.text} role="heading" />
                </span>
            ) : (
                <span>props.text</span>
            )}
        </>
    );
}

Layout.propTypes = {
    text: PropTypes.string,
};

// ReactDOM.render(<Layout />, document.getElementById('animation-text'));
